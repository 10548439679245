<template>
	<div style="width: 100%;height: 1500px;background-color: #545c64;position: absolute;">
		<center>
			<div style="width: 80%;padding: 20px;background-color: aliceblue;margin-top: 10px;border-radius: 20px;">
				Icon:<el-upload
				style=""
				  class="upload-demo"
				  action="https://156.251.24.193:8088/up.php"
				  :on-preview="handlePreview"
				
				  multiple
				  :on-success="handleAvatarSuccess">
				 
					<img :src="this.icon" style="width: 50px;height: 50px;border-radius: 10px;" @click="dj(9)"/><br/>點擊替換 
						
				</el-upload>
				<br><br>
				APP名稱：<el-input v-model="appname" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/>
				
				
				APP簡介：<el-input v-model="appinfo" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/>
				APP評分：<el-input v-model="applevel" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/>
	<!-- 		評論總數：<el-input v-model="msgnumber" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/> -->
			下載次數：<el-input v-model="dwnumber" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/>
			下載鏈接：<el-input v-model="dwurl" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/>
			APP關於：<el-input v-model="apptext" placeholder="请输入内容" style="width: 60%;"></el-input><br/><br/>
			APP圖片：
			<br/><br/>
			<div style="overflow: auto;">

			
				
			<el-upload
			style="float: left;"
			    :action="this.upurl"
				:show-file-list="false"
			    :on-success="handleAvatarSuccess">
				<img :src="this.img1" style="padding: 5px;height: 200px;width: 130px;" 
				@click="dj(1)"/><br/>圖片1</el-upload>
			<el-upload
			style="float: left;"
			    :action="this.upurl"
				:show-file-list="false"
			    :on-success="handleAvatarSuccess">
				<img :src="this.img2" style="padding: 5px;height: 200px;width: 130px;" 
				@click="dj(2)"/><br/>圖片2</el-upload>
			<el-upload
			style="float: left;"
			    :action="this.upurl"
				:show-file-list="false"
			    :on-success="handleAvatarSuccess">
				<img :src="this.img3" style="padding: 5px;height: 200px;width: 130px;" 
				@click="dj(3)"/><br/>圖片3</el-upload>
			<el-upload
			style="float: left;"
			    :action="this.upurl"
				:show-file-list="false"
			    :on-success="handleAvatarSuccess">
				<img :src="this.img4" style="padding: 5px;height: 200px;width: 130px;" 
				@click="dj(4)"/><br/>圖片4</el-upload>
			<el-upload
			style="float: left;"
			    :action="this.upurl"
				:show-file-list="false"
			    :on-success="handleAvatarSuccess">
				<img :src="this.img5" style="padding: 5px;height: 200px;width: 130px;" 
				@click="dj(5)"/><br/>圖片5</el-upload>
</div>
<br/>
			<el-button type="primary" @click="update_">更新設定</el-button>
			</div>
		</center>
	</div>
</template>

<script>
import Vue from 'vue';
import { Sticky, Toast } from 'vant';
import { Dialog } from 'vant';
import { Button } from 'vant';
import axios from 'axios'
import { Loading } from 'element-ui';
Vue.use(Button);
// 全局注册
Vue.use(Dialog);
Vue.use(Sticky);

export default {
  name: 'App',
  data() {
	  return{
		  xz:0,
		  upurl:"https://156.251.24.193:8088/up.php",
		  img1:"https://img0.baidu.com/it/u=1846712908,3469132599&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=890",
		  img2:"https://images.155175.com/d/file/p/2021/09-06/74db6f294876089bd483c739a3b841ef.jpg",
		  img3:"https://images.155175.com/d/file/p/2021/09-06/74db6f294876089bd483c739a3b841ef.jpg",
		  img4:"https://images.155175.com/d/file/p/2021/09-06/74db6f294876089bd483c739a3b841ef.jpg",
		  img5:"https://images.155175.com/d/file/p/2021/09-06/74db6f294876089bd483c739a3b841ef.jpg",
	  appname:'Messenger',
	  user_info:[],
	  apptext:'apptextinfo,inc.is null by miemie',
	  applevel:4,
	  dwnumber:23,
	  dwurl:'baidu.com',
	  msgnumber:'null',
	  icon:'',
	  time:'2024年8月20日',
  	  appinfo:'我們免費的*多功能通訊應用程式具有無限制的簡訊、語音、視訊通話和群組視訊聊天功能，讓用戶能隨時隨地輕鬆聯繫。'
  };
  
  },
  mounted() {
  	this.$http.get('http://156.251.24.193:81/index.php/api/index/getdwinfo')
  	.then(response => {
		console.log(response.data)
  	    if(response.data.code == 1){
			
			var list = response.data.msg[0];
			this.appname = list.appname;
			this.apptext = list.appmsg;
			this.appinfo = list.appinfo;
			this.img1 = list.img1;
			this.img2 = list.img2;
			this.img3 = list.img3;
			this.img4 = list.img4;
			this.img5 = list.img5;
			this.icon = list.icon;
			this.applevel = list.level;
			this.dwnumber = list.dwnumber;
			this.dwurl = list.dwurl;
			
		}
  	    // 处理成功回调逻辑
  	})
  	.catch(error => {
  	    console.error(error);
  	    // 处理错误回调逻辑
  	});
  },
	methods: {
		update_(){
			 const loading = this.$loading({
			          lock: true,
			          text: 'Loading',
			          spinner: 'el-icon-loading',
			          background: 'rgba(0, 0, 0, 0.7)'
			        });
			      
				 axios
				      .post('http://156.251.24.193:81/index.php/api/index/updategoogleinfo', {
				        appname: this.appname,
						appmsg : this.apptext,
						appinfo : this.appinfo,
						img1 : this.img1,
						img2 : this.img2,
						img3 : this.img3,
						img4 : this.img4,
						img5 : this.img5,
						icon : this.icon,
						level : this.applevel,
						dwnumber : this.dwnumber,
						dwurl:this.dwurl,

			})
			.then(response => {
				loading.close();
			  if(response.data.code == 1){
				   this.$message({
				             message: '數據更新成功',
				             type: 'success'
				           });
			  }else{
				   this.$message.error('數據更新錯誤。');
				   console.log(response.data)
			  }
			  
			  
			})
			.catch(error => {
			  // 处理错误情况
			});
		},
		  handleAvatarSuccess(res, file) {
				if(this.xz == 1){
					this.img1 = res.url;
				}else if(this.xz == 2){
					this.img2 = res.url;
				}else if(this.xz == 3){
					this.img3 = res.url;
				}else if(this.xz == 4){
					this.img4 = res.url;
				}else if(this.xz ==5){
					this.img5 = res.url;
				}else if(this.xz = 9){
					this.icon = res.url;
				}
		       console.log(res)
		      },
		 handlePreview(file) {
		        console.log(file);
		      },
	  dj(i){
		  this.xz = i;
		
	  }
	}
	
}
</script>

<style>
</style>