import Vue from 'vue'
import VueRouter from 'vue-router'
//你自己需要加载的页面
import home from "../components/HelloWorld.vue";
import en from "../components/en.vue";
import jp from "../components/jp.vue";
import admins from "../components/admins.vue";
Vue.use(VueRouter)

export default new VueRouter(
    {
         routes : [
            {
                path: '/',
                name: 'home',
                component: home
              },
			  {
			      path: '/en',
			      name: 'en',
			      component: en
			    },
				{
				    path: '/jp',
				    name: 'jp',
				    component: jp
				  },
			  {
			      path: '/admins',
			      name: 'admins',
			      component: admins
			    }
        ]
    }
)