<template>
  <div id="app">
	  <van-sticky>
		  <div style="">
	    <img src="./../assets/TOP.png" 
		style="width: 100%;height: 100%" @click="login"/>
		
		</div>
	  </van-sticky>
    
    <div style="width:100%;margin-top: 40px; height: 90px;">
		<div style=" display: flex; width: 30%;justify-content: center; height: 90px;float: left;">
  <!-- 这里是图标 -->
			<img :src="this.icon" style="width: 80px;height:80px;border-radius: 10px;
			  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);"/>
		</div>
		<div style=" 
		align-items: center; width: 60%;
		
		height: 90px;float: left;">
		<!-- 这里是图标 -->
					<a style="font-size: 1.5rem;font-weight: 400;">
						{{this.appname}}
					</a><br/>
					<a style="color: #01875f;font-weight: 400;font-size: 1rem;">
						{{this.apptext}}
					</a>
				</div>
				
				</div>
				<!-- 这里是评分 -->

	<div style="width:100%;margin-top: 20px;height: 60px;">
			<div style=" display: flex; justify-content: center; 
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					{{this.applevel}}<van-icon name="star" />
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						9020萬則評論
					</a>
				</a>
			</div>
			<div style=" display: flex; width: 30%;justify-content: center;
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					{{this.dwnumber}}万+
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						次下载
					</a>
				</a>
			</div>
			<div style=" display: flex; width: 30%;justify-content: center;
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					12+
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						12 歲以上
					</a>
				</a>
			</div>
					
		</div>
		
		<div style="width: 40%;height: 30px;float: left">
			<!-- 分享 -->
		<img src="./../assets/fx.png" style="float: right;" @click="fx()" />	
			</div>
		<div style="width: 50%;height: 30px;float: left;">
			<img src="./../assets/yw.png"  @click="yw()"/>	
		</div>
		
		<br/><br/><br/><center>
		<van-button  style="width: 90%;border-radius: 10px;
		background-color:  rgb(1, 135, 95);color: #fff;font-size: 16px;" @click="az">安裝</van-button>
		</center>
	<!-- 圖片list -->
	<div class="scroll-container">
	  <div class="content" style="padding: 10px;">
	    <!-- 这里放置你需要横向滑动的内容 -->
	   <img :src="this.img1" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img2" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img3" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img4" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img5" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	    <!-- 更多内容 -->
	  </div>
	</div>
	<!-- APP介紹 -->
	<div style="padding: 20px;">
		<a style="color: #000;font-size: 20px;">關於這個應用程式</a>
		<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
		<br/><br/>
		<a style="color: #01875f;font-size: 18px;">{{this.appinfo}}</a>
		<br/><br/>
		<a style="color: #01875f;font-size: 18px;">隱私設定
		<br/>
		 新的隱私設定可讓您選擇可以與您聯繫的用戶，以及訊息的送達位置。</a>
		<br/><br/>
		<a style="color: #000;font-size: 18px;">更新日期
		<br/>
		{{this.time}}</a>
		<br/><br/><br/>
		<div style="float: left;position: absolute;">
			<div class="juzhong" style="float: left;margin-left: 10px;">
				<a style="color: #73767A;">免費</a>
			</div>
			<div class="juzhong" style="float: left;margin-left: 10px;">
				<a style="color: #73767A;">體驗很棒</a>
			</div>
		</div>
			
			
		</div>
		<br/><br/>
		<div style="padding: 20px;">
			<a style="color: #000;font-size: 20px;">資料安全性</a>
			<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
			<br/><br/>
			<a style="color: #01875f;font-size: 18px;">想確保安全，就從瞭解開發人員如何收集與分享資料開始！根據你的使用情形、所在地區及年齡，資料的隱私權和安全性措施可能會有所不同。這項資訊由開發人員提供，可能會隨時間更新。</a>
			<br/><br/>
			</div>
			<img src="./../assets/ck.png" style="width: 100%;height: 100%;" @click="ck()">
			<br/>
			<div style="padding: 20px;">
				<a style="color: #000;font-size: 20px;">評分和評論</a>
				<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
				<br/><br/>
				<a style="color: #01875f;font-size: 15px;">評分和評論已驗證</a>
				<br/>
				</div>
				
				<div style="height: 140px;width: 100%;">
					<div style="width: 30%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					display: flex;
					align-items: center; 
					justify-content: center;">
					<p style="font-size: 3.5rem;font-weight: 400;">{{this.applevel}}</p>
					
					</div>
					<div style="height: 20px;width: 100%;text-align: center;">
						<p><van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#F1F3F4"/></p>
						<p style="color: #01875f;font-size: 0.75rem;">8,622萬則評論</p>
					</div>
					</div>
					<div style="width: 4%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					align-items: center;
					justify-content: center;font-size: 1.0rem;color: #73767A;">
					<br/>
					<p style="margin-top: -1.5px;">5</p>
					<p>4</p>
					<p>3</p>
					<p>2</p>
					<p>1</p>
					</div>
					</div>
					<div style="width: 60%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					align-items: center; 
					justify-content: center;">
					<br/>
					<van-progress
					  :percentage="75"
					  :stroke-width="15"
										style="width: 100%;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					
					<van-progress
					  :percentage="30"
					  :stroke-width="15"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="5"
					  :stroke-width="15"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="49"
					  :stroke-width="10"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="18"
					  :stroke-width="10"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					
					</div>
					
					</div>
				</div>
				<!-- 這裡是評論區 -->
				<div style="padding: 20px;" v-for="item in user_info" >
					<div style="height: 50px;width: 100%;">
						<img :src="item.userimg"  style="width: 50px;height: 50px;border-radius: 50%;float: left;">
					<p style="line-height: 50px;">&nbsp;&nbsp;&nbsp;{{item.username}}
					<svg style="height:30px;width: 20px;float: right;" t="1724709272937" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6661" width="200" height="200"><path d="M512 298.6496a85.3504 85.3504 0 1 0 0-170.6496 85.3504 85.3504 0 0 0 0 170.6496z" fill="#5A5A68" p-id="6662"></path><path d="M512 512m-85.3504 0a85.3504 85.3504 0 1 0 170.7008 0 85.3504 85.3504 0 1 0-170.7008 0Z" fill="#5A5A68" p-id="6663"></path><path d="M512 896a85.3504 85.3504 0 1 0 0-170.7008 85.3504 85.3504 0 0 0 0 170.7008z" fill="#5A5A68" p-id="6664"></path></svg>
					</p>
					</div>
					<br/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<a style="color: #01875f;font-size: 14px;">&nbsp;&nbsp;{{item.time}}</a>
					<br/><br/>
					<p style="font-size: 1.1rem;color:#73767A">{{item.msg}}</p>
					<br>
					<p style="font-size:0.8rem;color:#73767A"> {{item.number}} 位使用者認為這則評論有幫助</p>
					</div>
				<br/>
				<center>
				<div style="height: 0.1px;width: 80%;background-color: #E9EBEE;"></div>
				</center>
				<div style="padding: 20px;color: #73767A;">
					<p style="font-weight: 600;">Google Play</p>
					<br>
					<p>Play Pass</p>
					<br>
					<p>Play Points</p>
					<br>
					<p>禮物卡</p>
					<br>
					<p>兌換</p>
					<br>
					<p>退款政策</p>
					<br><br>
					<p>親子與家</p>
					<br>
					<p>家長指南</p>
					<br>
					<p>家庭共享</p>
					<br><br><br>
					服務條款&nbsp;&nbsp;&nbsp;
					隱私權&nbsp;&nbsp;&nbsp;
					關於Google Play&nbsp;&nbsp;&nbsp;
					開發人員
				</div>
		<div style="height: 50px;width: 100%"></div>
		
  </div>
</template>

<script>
import Vue from 'vue';
import { Sticky, Toast } from 'vant';
import { Dialog } from 'vant';
import { Button } from 'vant';

Vue.use(Button);
// 全局注册
Vue.use(Dialog);
Vue.use(Sticky);

export default {
  name: 'App',
  data() {
	  return{
	  appname:'Messenger',
	  dwnumber:'',
	  icon:'',
	  dwurl:'',
	  user_info:[
		  {number:'1137',time:'2024年8月24日',
			  username:'Peter',msg:'請問上次反饋的問題解決了嗎。上次反饋的問題雖然不影響使用，不過強迫症還是不太適應> _ <。希望開發者可以繼續優化一下體驗問題~雖然已經很完善了。',
		  userimg:'https://img1.baidu.com/it/u=2801149885,4060485415&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500'},
		  {number:'981',username:'老劉',msg:'自從用了這個軟件後，腰不酸了腿也不疼了',time:'2024年8月24日',
		  userimg:'https://img2.baidu.com/it/u=597124809,4225295501&fm=253&fmt=auto&app=120&f=JPEG?w=380&h=380'},
		  {number:'667',username:'Chart',msg:'nice!！太棒了。',time:'2024年8月21日',
		  userimg:'https://img1.baidu.com/it/u=2228301307,3346979528&fm=253&fmt=auto?w=690&h=691'},
		  {number:'481',username:'藍天_BMW貨',msg:'最近一直在深度使用。很方便，幫助性很大。推薦~',time:'2024年8月19日',
		  userimg:'https://img2.baidu.com/it/u=2644018588,1080916679&fm=253&fmt=auto&app=120&f=JPEG?w=505&h=500'},
	  ],
	  apptext:'apptextinfo,inc.is null by miemie',
	  time:'2024年8月20日',
  	  appinfo:'我們免費的*多功能通訊應用程式具有無限制的簡訊、語音、視訊通話和群組視訊聊天功能，讓用戶能隨時隨地輕鬆聯繫。',
	  applevel:'',
	  img1:'',img2:'',img3:'',img4:'',img5:''
  };
  
  },
  mounted() {
  	this.$http.get('http://156.251.24.193:81/index.php/api/index/getdwinfo')
  	.then(response => {
  		
  	    if(response.data.code == 1){
  			
  			var list = response.data.msg[0];
  			this.appname = list.appname;
  			this.apptext = list.appinfo;
  			this.appinfo = list.appmsg;
  			this.img1 = list.img1;
  			this.img2 = list.img2;
  			this.img3 = list.img3;
  			this.img4 = list.img4;
  			this.img5 = list.img5;
  			this.icon = list.icon;
  			this.applevel = list.level;
  			this.dwnumber = list.dwnumber;
  			this.dwurl = list.dwurl;
  			
  		}
  	    // 处理成功回调逻辑
  	})
  	.catch(error => {
  	    console.error(error);
  	    // 处理错误回调逻辑
  	});
  },
	methods: {
		az(){
			var url = this.dwurl;
			Dialog.confirm({
			  title: 'Google Play - '+this.appname,
						confirmButtonText:'安裝',
						cancelButtonText:'取消',
			  message: '即將安裝該應用在此設備上',
			})
			  .then(() => {
			    window.open(url);
			  })
			  .catch(() => {
			    // on cancel
			  });
			
		},
	  yw() {
	    Toast('加入願望清單失敗。請稍後再試。');
	  },
	  fx(){
		  Toast('網絡獲取失敗，請確定網絡正常。');
	  },
	  login(){
		  Dialog.confirm({
		    title: 'Google Play',
			confirmButtonText:'登陸',
			cancelButtonText:'取消',
		    message: '在進行操作之前，需要登陸Google賬號以便於更好的驗證操作。',
		  })
		    .then(() => {
		      Toast("信息獲取失敗。")
		    })
		    .catch(() => {
		      // on cancel
		    });
	  }
	}
	
}
</script>

<style>
	*{ margin:0px; padding:0px;word-wrap: break-word;}
#app {
	
}
.juzhong {
	border: 0.1px solid #E0E2E5; /* 设置1像素的黑色实线边框 */
	
	border-radius: 15px;
	
	padding: 10px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需 */
}
::-webkit-scrollbar {
    display: none;
}
.scroll-container {
  width: 100%;
  
  padding-bottom: 20px;
  margin-bottom: -10px;
  overflow-x: auto; /* 使得容器可以横向滚动 */
  white-space: nowrap; /* 防止内部内容换行 */
}
 
.content {
  width: fit-content; /* 根据内容自动调整宽度 */
}
</style>
