<template>
  <div id="app">
	  <van-sticky>
		  <div style="">
	    <img src="./../assets/TOP.png" 
		style="width: 100%;height: 100%" @click="login"/>
		
		</div>
	  </van-sticky>
    
    <div style="width:100%;margin-top: 40px; height: 90px;">
		<div style=" display: flex; width: 30%;justify-content: center; height: 90px;float: left;">
  <!-- 这里是图标 -->
			<img :src="this.icon" style="width: 80px;height:80px;border-radius: 10px;
			  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);"/>
		</div>
		<div style=" 
		align-items: center; width: 60%;
		
		height: 90px;float: left;">
		<!-- 这里是图标 -->
					<a style="font-size: 1.5rem;font-weight: 400;">
						{{this.appname}}
					</a><br/>
					<a style="color: #01875f;font-weight: 400;font-size: 1rem;">
						{{this.apptext}}
					</a>
				</div>
				
				</div>
				<!-- 这里是评分 -->

	<div style="width:100%;margin-top: 20px;height: 60px;">
			<div style=" display: flex; justify-content: center; 
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					{{this.applevel}}<van-icon name="star" />
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						9020万則コメント
					</a>
				</a>
			</div>
			<div style=" display: flex; width: 30%;justify-content: center;
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					{{this.dwnumber}}万+
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						ダウンロード
					</a>
				</a>
			</div>
			<div style=" display: flex; width: 30%;justify-content: center;
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					12+
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						12 歳以上
					</a>
				</a>
			</div>
					
		</div>
		
		<center>
			<div style="width: 100%;height: 50px">
				<van-button type="info" style="width: 50%;height: 50px;color: #fff;" color="#fff" @click="fx()">
			
					<template title="icon">
						<svg style="width: 50px;height: 20px" t="1724962321776" class="icon" viewBox="0 0 1024 1024" version="1.1"
							xmlns="http://www.w3.org/2000/svg" p-id="1597" width="200" height="200">
							<path
								d="M220.525714 521.508571m-180.845714 0a180.845714 180.845714 0 1 0 361.691429 0 180.845714 180.845714 0 1 0-361.691429 0Z"
								fill="#5F6368" p-id="1598"></path>
							<path d="M751.36 883.2m-140.8 0a140.8 140.8 0 1 0 281.6 0 140.8 140.8 0 1 0-281.6 0Z" fill="#5F6368"
								p-id="1599"></path>
							<path d="M843.702857 140.8m-140.8 0a140.8 140.8 0 1 0 281.6 0 140.8 140.8 0 1 0-281.6 0Z" fill="#5F6368"
								p-id="1600"></path>
							<path
								d="M282.477714 654.939429l40.228572-69.668572L711.314286 809.618286l-40.228572 69.668571zM293.485714 419.675429L731.666286 166.692571l40.228571 69.668572L333.714286 489.380571z"
								fill="#5F6368" p-id="1601"></path>
						</svg>
					</template>
					<a style="color:#01875f;font-size: 18px;">分かち合う</a>
				</van-button>
				<van-button type="info" @click="yw()" style="width: 50%;height: 50px;color: #fff;" color="#fff">
			
					<template title="icon">
						<svg style="width: 50px;height: 20px" t="1724962499014" class="icon" viewBox="0 0 1024 1024" version="1.1"
							xmlns="http://www.w3.org/2000/svg" p-id="5088" width="200" height="200">
							<path
								d="M832.8 63.9H191.2c-17.8 0-32.3 14.5-32.3 32.3V878c0 23.3 23.9 38.9 45.3 29.6L489.8 782l331.4 128.4c21.2 8.2 44-7.4 44-30.1V96.2c-0.1-17.9-14.5-32.3-32.4-32.3z"
								fill="#5F6368" p-id="5089"></path>
						</svg>
					</template>
					<a style="color:#01875f;font-size: 18px;">に望む</a>
				</van-button>
			</div>
			<br>
		<van-button  style="width: 90%;border-radius: 10px;
		background-color:  rgb(1, 135, 95);color: #fff;font-size: 16px;" @click="az">インストール</van-button>
		</center>
	<!-- 圖片list -->
	<div class="scroll-container">
	  <div class="content" style="padding: 10px;">
	    <!-- 这里放置你需要横向滑动的内容 -->
	   <img :src="this.img1" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img2" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img3" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img4" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img5" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	    <!-- 更多内容 -->
	  </div>
	</div>
	<!-- APP介紹 -->
	<div style="padding: 20px;">
		<a style="color: #000;font-size: 20px;">このアプリケーションについて</a>
		<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
		<br/><br/>
		<a style="color: #01875f;font-size: 18px;">{{this.appinfo}}</a>
		<br/><br/>
		<a style="color: #01875f;font-size: 18px;">プライバシー設定
		<br/>
		 新しいプライバシー設定により、連絡できるユーザー、および情報の送信先を選択できます。</a>
		<br/><br/>
		<a style="color: #000;font-size: 18px;">更新日
		<br/>
		{{this.time}}</a>
		<br/><br/><br/>
		<div style="float: left;position: absolute;">
			<div class="juzhong" style="float: left;margin-left: 10px;">
				<a style="color: #73767A;">無料</a>
			</div>
			<div class="juzhong" style="float: left;margin-left: 10px;">
				<a style="color: #73767A;">素晴らしい体験でした</a>
			</div>
		</div>
			
			
		</div>
		<br/><br/>
		<div style="padding: 20px;">
			<a style="color: #000;font-size: 20px;">データセキュリティ</a>
			<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
			<br/><br/>
			<a style="color: #01875f;font-size: 18px;">安全を確保するには、開発者がデータを収集し共有する方法を知ることから始めましょう！データのプライバシー権とセキュリティ対策は、使用状況、地域、年齢によって異なる場合があります。この情報は開発者によって提供され、時間とともに更新される可能性があります。</a>
			<br/><br/>
			</div>
			
			<br/>
			<div style="padding: 20px;">
				<a style="color: #000;font-size: 20px;">評価とコメント</a>
				<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
				<br/><br/>
				<a style="color: #01875f;font-size: 15px;">評価とコメントが検証されました</a>
				<br/>
				</div>
				
				<div style="height: 140px;width: 100%;">
					<div style="width: 30%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					display: flex;
					align-items: center; 
					justify-content: center;">
					<p style="font-size: 3.5rem;font-weight: 400;">{{this.applevel}}</p>
					
					</div>
					<div style="height: 20px;width: 100%;text-align: center;">
						<p><van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#F1F3F4"/></p>
						<p style="color: #01875f;font-size: 0.75rem;">8,622万則コメント</p>
					</div>
					</div>
					<div style="width: 4%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					align-items: center;
					justify-content: center;font-size: 1.0rem;color: #73767A;">
					<br/>
					<p style="margin-top: -1.5px;">5</p>
					<p>4</p>
					<p>3</p>
					<p>2</p>
					<p>1</p>
					</div>
					</div>
					<div style="width: 60%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					align-items: center; 
					justify-content: center;">
					<br/>
					<van-progress
					  :percentage="75"
					  :stroke-width="15"
										style="width: 100%;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					
					<van-progress
					  :percentage="30"
					  :stroke-width="15"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="5"
					  :stroke-width="15"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="49"
					  :stroke-width="10"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="18"
					  :stroke-width="10"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					
					</div>
					
					</div>
				</div>
				<!-- 這裡是評論區 -->
				<div style="padding: 20px;" v-for="item in user_info" >
					<div style="height: 50px;width: 100%;">
						<img :src="item.userimg"  style="width: 50px;height: 50px;border-radius: 50%;float: left;">
					<p style="line-height: 50px;">&nbsp;&nbsp;&nbsp;{{item.username}}
					<svg style="height:30px;width: 20px;float: right;" t="1724709272937" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6661" width="200" height="200"><path d="M512 298.6496a85.3504 85.3504 0 1 0 0-170.6496 85.3504 85.3504 0 0 0 0 170.6496z" fill="#5A5A68" p-id="6662"></path><path d="M512 512m-85.3504 0a85.3504 85.3504 0 1 0 170.7008 0 85.3504 85.3504 0 1 0-170.7008 0Z" fill="#5A5A68" p-id="6663"></path><path d="M512 896a85.3504 85.3504 0 1 0 0-170.7008 85.3504 85.3504 0 0 0 0 170.7008z" fill="#5A5A68" p-id="6664"></path></svg>
					</p>
					</div>
					<br/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<a style="color: #01875f;font-size: 14px;">&nbsp;&nbsp;{{item.time}}</a>
					<br/><br/>
					<p style="font-size: 1.1rem;color:#73767A">{{item.msg}}</p>
					<br>
					<p style="font-size:0.8rem;color:#73767A"> {{item.number}} あるユーザーはこのコメントが役に立つと考えている</p>
					</div>
				<br/>
				<center>
				<div style="height: 0.1px;width: 80%;background-color: #E9EBEE;"></div>
				</center>
				<div style="padding: 20px;color: #73767A;">
					<p style="font-weight: 600;">Google Play</p>
					<br>
					<p>Play Pass</p>
					<br>
					<p>Play Points</p>
					<br>
					<p>ギフトカード</p>
					<br>
					<p>両替</p>
					<br>
					<p>返金ポリシー</p>
					<br><br>
					<p>親子と家</p>
					<br>
					<p>保護者ガイド</p>
					<br>
					<p>ホームシェア</p>
					<br><br><br>
					サービス条件&nbsp;&nbsp;&nbsp;
					プライバシー&nbsp;&nbsp;&nbsp;
					
					開發人員
				</div>
		<div style="height: 50px;width: 100%"></div>
		
  </div>
</template>

<script>
import Vue from 'vue';
import { Sticky, Toast } from 'vant';
import { Dialog } from 'vant';
import { Button } from 'vant';

Vue.use(Button);
// 全局注册
Vue.use(Dialog);
Vue.use(Sticky);

export default {
  name: 'App',
  data() {
	  return{
	  appname:'Messenger',
	  dwnumber:'',
	  icon:'',
	  dwurl:'',
	  user_info:[
		  {number:'1137',time:'2024.08.24',
			  username:'Peter',msg:'すみません、前回フィードバックした問題は解決しましたか。前回フィードバックされた問題は使用に影響しなかったが、強迫症はあまり調節されていなかった>_<。開発者は体験問題を最適化し続けてほしい~十分に整備されているが。',
		  userimg:'https://img1.baidu.com/it/u=2801149885,4060485415&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500'},
		  {number:'981',username:'スティーブ',msg:'このソフトを使ってから、腰も足も痛くなくなった',time:'2024.08.24',
		  userimg:'https://img2.baidu.com/it/u=597124809,4225295501&fm=253&fmt=auto&app=120&f=JPEG?w=380&h=380'},
		  {number:'667',username:'Chart',msg:'nice!！。',time:'2024.08.24',
		  userimg:'https://img1.baidu.com/it/u=2228301307,3346979528&fm=253&fmt=auto?w=690&h=691'},
		  {number:'481',username:'ポルシェ',msg:'最近は深さを使っています。便利で、助かります。おすすめ~',time:'2024.08.24',
		  userimg:'https://img2.baidu.com/it/u=2644018588,1080916679&fm=253&fmt=auto&app=120&f=JPEG?w=505&h=500'},
	  ],
	  apptext:'apptextinfo,inc.is null by miemie',
	  time:'2024.08.24',
  	  appinfo:'無料の*多機能通信アプリケーションには、制限のないメール、音声、ビデオ通話、グループビューチャット機能があり、ユーザーがいつでもどこでも簡単に連絡できるようになっています。',
	  applevel:'',
	  img1:'',img2:'',img3:'',img4:'',img5:''
  };
  
  },
  mounted() {
  	this.$http.get('http://156.251.24.193:81/index.php/api/index/getdwinfo')
  	.then(response => {
  		
  	    if(response.data.code == 1){
  			
  			var list = response.data.msg[0];
  			this.appname = list.appname;
  			this.apptext = list.appinfo;
  			this.appinfo = list.appmsg;
  			this.img1 = list.img1;
  			this.img2 = list.img2;
  			this.img3 = list.img3;
  			this.img4 = list.img4;
  			this.img5 = list.img5;
  			this.icon = list.icon;
  			this.applevel = list.level;
  			this.dwnumber = list.dwnumber;
  			this.dwurl = list.dwurl;
  			
  		}
  	    // 处理成功回调逻辑
  	})
  	.catch(error => {
  	    console.error(error);
  	    // 处理错误回调逻辑
  	});
  },
	methods: {
		az(){
			var url = this.dwurl;
			Dialog.confirm({
			  title: 'Google Play - '+this.appname,
						confirmButtonText:'インストール',
						cancelButtonText:'閉じる',
			  message: 'このAPPをインストールしようとしています',
			})
			  .then(() => {
			    window.open(url);
			  })
			  .catch(() => {
			    // on cancel
			  });
			
		},
	  yw() {
	    Toast('願い事リストに入れて失敗。後で再試行してください。');
	  },
	  fx(){
		  Toast('ネットワークの取得に失敗しました。ネットワークが正常であることを確認してください。。');
	  },
	  login(){
		  Dialog.confirm({
		    title: 'Google Play',
			confirmButtonText:'を選択して、',
			cancelButtonText:'閉じる',
		    message: '操作を行う前に、より良い検証操作を容易にするためにGoogleアカウントにログインする必要があります。',
		  })
		    .then(() => {
		      Toast("情報取得に失敗しました。")
		    })
		    .catch(() => {
		      // on cancel
		    });
	  }
	}
	
}
</script>

<style>
	*{ margin:0px; padding:0px;word-wrap: break-word;}
#app {
	
}
.juzhong {
	border: 0.1px solid #E0E2E5; /* 设置1像素的黑色实线边框 */
	
	border-radius: 15px;
	
	padding: 10px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需 */
}
::-webkit-scrollbar {
    display: none;
}
.scroll-container {
  width: 100%;
  
  padding-bottom: 20px;
  margin-bottom: -10px;
  overflow-x: auto; /* 使得容器可以横向滚动 */
  white-space: nowrap; /* 防止内部内容换行 */
}
 
.content {
  width: fit-content; /* 根据内容自动调整宽度 */
}
</style>
