import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router/index.js'
import axios from 'axios';
// 创建并使用一个自定义实例（可选）
const request = axios.create({
    headers: {'Content-Type': 'application/json;charset=UTF-8'},
    // 其他自定义配置项...
});
Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype.$api = request; 
Vue.use(ElementUI);
// 全局注册
Vue.use(Vant);

new Vue({
	router,
  render: h => h(App)
}).$mount('#app');