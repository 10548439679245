<template>
  <div id="app">
	  <van-sticky>
		  <div style="">
	    <img src="./../assets/TOP.png" 
		style="width: 100%;height: 100%" @click="login"/>
		
		</div>
	  </van-sticky>
    
    <div style="width:100%;margin-top: 40px; height: 90px;">
		<div style=" display: flex; width: 30%;justify-content: center; height: 90px;float: left;">
  <!-- 这里是图标 -->
			<img :src="this.icon" style="width: 80px;height:80px;border-radius: 10px;
			  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);"/>
		</div>
		<div style=" 
		align-items: center; width: 60%;
		
		height: 90px;float: left;">
		<!-- 这里是图标 -->
					<a style="font-size: 1.5rem;font-weight: 400;">
						{{this.appname}}
					</a><br/>
					<a style="color: #01875f;font-weight: 400;font-size: 1rem;">
						{{this.apptext}}
					</a>
				</div>
				
				</div>
				<!-- 这里是评分 -->

	<div style="width:100%;margin-top: 20px;height: 60px;">
			<div style=" display: flex; justify-content: center; 
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					{{this.applevel}}<van-icon name="star" />
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						9020Ten thousand comments
					</a>
				</a>
			</div>
			<div style=" display: flex; width: 30%;justify-content: center;
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					{{this.dwnumber}}ten thousand+
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						Downloads
					</a>
				</a>
			</div>
			<div style=" display: flex; width: 30%;justify-content: center;
			height:60px;float: left;
			display: flex; width: 30%;justify-content: center;text-align: center;">
				<a style="font-weight: 600px;font-size: 14px;">
					12+
					<br/>
					<a style="color: rgb(95, 99, 104);font-weight: 200;font-size: 12px;">
						12 Age and above
					</a>
				</a>
			</div>
					
		</div>
		
		
		
		
		<center>
		<div style="width: 100%;height: 50px">
			<van-button type="info" 
			style="width: 50%;height: 50px;color: #fff;" color="#fff"
			@click="fx()" >
			  
			  <template title="icon">
				  <svg 
				  style="width: 50px;height: 20px"
				  t="1724962321776" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1597" width="200" height="200"><path d="M220.525714 521.508571m-180.845714 0a180.845714 180.845714 0 1 0 361.691429 0 180.845714 180.845714 0 1 0-361.691429 0Z" fill="#5F6368" p-id="1598"></path><path d="M751.36 883.2m-140.8 0a140.8 140.8 0 1 0 281.6 0 140.8 140.8 0 1 0-281.6 0Z" fill="#5F6368" p-id="1599"></path><path d="M843.702857 140.8m-140.8 0a140.8 140.8 0 1 0 281.6 0 140.8 140.8 0 1 0-281.6 0Z" fill="#5F6368" p-id="1600"></path><path d="M282.477714 654.939429l40.228572-69.668572L711.314286 809.618286l-40.228572 69.668571zM293.485714 419.675429L731.666286 166.692571l40.228571 69.668572L333.714286 489.380571z" fill="#5F6368" p-id="1601"></path></svg>
			  </template>
			  <a style="color:#01875f;font-size: 18px;">Share</a>
			</van-button>
			<van-button type="info"  @click="yw()"
			style="width: 50%;height: 50px;color: #fff;" color="#fff">
			  
			  <template title="icon">
				  <svg 
				  style="width: 50px;height: 20px"
				  t="1724962499014" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5088" width="200" height="200"><path d="M832.8 63.9H191.2c-17.8 0-32.3 14.5-32.3 32.3V878c0 23.3 23.9 38.9 45.3 29.6L489.8 782l331.4 128.4c21.2 8.2 44-7.4 44-30.1V96.2c-0.1-17.9-14.5-32.3-32.4-32.3z" fill="#5F6368" p-id="5089"></path></svg>
				</template>
			  <a style="color:#01875f;font-size: 18px;">Collect</a>
			</van-button>
		</div>
		<van-button  style="width: 90%;border-radius: 10px;
		background-color:  rgb(1, 135, 95);color: #fff;font-size: 16px;" @click="az">INSTALL</van-button>
		</center>
	<!-- 圖片list -->
	<div class="scroll-container">
	  <div class="content" style="padding: 10px;">
	    <!-- 这里放置你需要横向滑动的内容 -->
	   <img :src="this.img1" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img2" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img3" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img4" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	   <img :src="this.img5" alt=""  style="padding: 10px;border-radius: 20px;height: 200px;width: 132px"/>
	    <!-- 更多内容 -->
	  </div>
	</div>
	<!-- APP介紹 -->
	<div style="padding: 20px;">
		<a style="color: #000;font-size: 20px;">Regarding this application</a>
		<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
		<br/><br/>
		<a style="color: #01875f;font-size: 18px;">{{this.appinfo}}</a>
		<br/><br/>
		<a style="color: #01875f;font-size: 18px;">Privacy settings
		<br/>
	The new privacy settings allow you to choose the users you can contact and the delivery location of messages。</a>
		<br/><br/>
		<a style="color: #000;font-size: 18px;">Update Date
		<br/>
		{{this.time}}</a>
		<br/><br/><br/>
		<div style="float: left;position: absolute;">
			<div class="juzhong" style="float: left;margin-left: 10px;">
				<a style="color: #73767A;">free</a>
			</div>
			<div class="juzhong" style="float: left;margin-left: 10px;">
				<a style="color: #73767A;">Great experience</a>
			</div>
		</div>
			
			
		</div>
		<br/><br/>
		<div style="padding: 20px;">
			<a style="color: #000;font-size: 20px;">Data security</a>
			<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
			<br/><br/>
			<a style="color: #01875f;font-size: 18px;">To ensure safety, start by understanding how developers collect and share data! The privacy and security measures for your data may vary depending on your usage, location, and age. This information is provided by developers and may be updated over time.</a>
			<br/><br/>
			</div>
			<!-- <img src="./../assets/ck.png" style="width: 100%;height: 100%;" @click="ck()"> -->
			<br/>
			<div style="padding: 20px;">
				<a style="color: #000;font-size: 20px;">Rating and comments</a>
				<svg style="height:30px;width: 20px;float: right;" t="1724704391378" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5713" id="mx_n_1724704391379" width="200" height="200"><path d="M512 959.68L384 896l320-320H64v-128h640L384 128l128-63.68L959.68 512z" fill="#5F6368" p-id="5714"></path></svg>
				<br/><br/>
				<a style="color: #01875f;font-size: 15px;">Rating and comments have been verified</a>
				<br/>
				</div>
				
				<div style="height: 140px;width: 100%;">
					<div style="width: 30%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					display: flex;
					align-items: center; 
					justify-content: center;">
					<p style="font-size: 3.5rem;font-weight: 400;">{{this.applevel}}</p>
					
					</div>
					<div style="height: 20px;width: 100%;text-align: center;">
						<p><van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#01875f"/>
						<van-icon name="star" color="#F1F3F4"/></p>
						<p style="color: #01875f;font-size: 0.75rem;">8,622Million Comment</p>
					</div>
					</div>
					<div style="width: 4%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					align-items: center;
					justify-content: center;font-size: 1.0rem;color: #73767A;">
					<br/>
					<p style="margin-top: -1.5px;">5</p>
					<p>4</p>
					<p>3</p>
					<p>2</p>
					<p>1</p>
					</div>
					</div>
					<div style="width: 60%;height: 140;float: left;">
					<div style="height: 90px;width: 100%;
					align-items: center; 
					justify-content: center;">
					<br/>
					<van-progress
					  :percentage="75"
					  :stroke-width="15"
										style="width: 100%;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					
					<van-progress
					  :percentage="30"
					  :stroke-width="15"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="5"
					  :stroke-width="15"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="49"
					  :stroke-width="10"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					<van-progress
					  :percentage="18"
					  :stroke-width="10"
										style="width: 100%;margin-top: 5px;"
					  pivot-text=""
					  track-color="#E8EAED"
					  color="#01875F"
					/>
					
					</div>
					
					</div>
				</div>
				<!-- 這裡是評論區 -->
				<div style="padding: 20px;" v-for="item in user_info" >
					<div style="height: 50px;width: 100%;">
						<img :src="item.userimg"  style="width: 50px;height: 50px;border-radius: 50%;float: left;">
					<p style="line-height: 50px;">&nbsp;&nbsp;&nbsp;{{item.username}}
					<svg style="height:30px;width: 20px;float: right;" t="1724709272937" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6661" width="200" height="200"><path d="M512 298.6496a85.3504 85.3504 0 1 0 0-170.6496 85.3504 85.3504 0 0 0 0 170.6496z" fill="#5A5A68" p-id="6662"></path><path d="M512 512m-85.3504 0a85.3504 85.3504 0 1 0 170.7008 0 85.3504 85.3504 0 1 0-170.7008 0Z" fill="#5A5A68" p-id="6663"></path><path d="M512 896a85.3504 85.3504 0 1 0 0-170.7008 85.3504 85.3504 0 0 0 0 170.7008z" fill="#5A5A68" p-id="6664"></path></svg>
					</p>
					</div>
					<br/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<van-icon name="star" color="#01875f" size="14px"/>
					<a style="color: #01875f;font-size: 14px;">&nbsp;&nbsp;{{item.time}}</a>
					<br/><br/>
					<p style="font-size: 1.1rem;color:#73767A">{{item.msg}}</p>
					<br>
					<p style="font-size:0.8rem;color:#73767A"> {{item.number}} user thinks this comment is helpful</p>
					</div>
				<br/>
				<center>
				<div style="height: 0.1px;width: 80%;background-color: #E9EBEE;"></div>
				</center>
				<div style="padding: 20px;color: #73767A;">
					<p style="font-weight: 600;">Google Play</p>
					<br>
					<p>Play Pass</p>
					<br>
					<p>Play Points</p>
					<br>
					<p>Gift Card</p>
					<br>
					<p>exchange</p>
					<br>
					<p>Refund Policy</p>
					<br><br>
					<p>Parenting and Home</p>
					<br>
					<p>Parent's Guide</p>
					<br>
					<p>Home Sharing</p>
					<br>
				</div>
		<div style="height: 50px;width: 100%"></div>
		
  </div>
</template>

<script>
import Vue from 'vue';
import { Sticky, Toast } from 'vant';
import { Dialog } from 'vant';
import { Button } from 'vant';

Vue.use(Button);
// 全局注册
Vue.use(Dialog);
Vue.use(Sticky);

export default {
  name: 'App',
  data() {
	  return{
	  appname:'Messenger',
	  dwnumber:'',
	  icon:'',
	  dwurl:'',
	  user_info:[
		  {number:'1137',time:'2024.8.24',
			  username:'Peter',msg:'Has the issue reported last time been resolved. Although the issue I reported last time did not affect my use, I still find it difficult to adapt to OCD. I hope developers can continue to optimize the experience issue, although it is already very comprehensive.',
		  userimg:'https://img1.baidu.com/it/u=2801149885,4060485415&fm=253&fmt=auto&app=120&f=JPEG?w=500&h=500'},
		  {number:'981',username:'Mr_Liu',msg:'Since using this software, my waist and legs no longer ache',time:'2024.8.24',
		  userimg:'https://img2.baidu.com/it/u=597124809,4225295501&fm=253&fmt=auto&app=120&f=JPEG?w=380&h=380'},
		  {number:'667',username:'Chart',msg:'nice!！',time:'2024.8.24',
		  userimg:'https://img1.baidu.com/it/u=2228301307,3346979528&fm=253&fmt=auto?w=690&h=691'},
		  {number:'481',username:'藍天_BMW貨',msg:'I have been using it extensively recently. Very convenient and very helpful. Recommended~',time:'2024.8.24',
		  userimg:'https://img2.baidu.com/it/u=2644018588,1080916679&fm=253&fmt=auto&app=120&f=JPEG?w=505&h=500'},
	  ],
	  apptext:'apptextinfo,inc.is null by miemie',
	  time:'2024.8.24',
  	  appinfo:'Our free multi-functional communication application features unlimited SMS, voice, video calling, and group video chat functions, allowing users to easily connect anytime, anywhere.',
	  applevel:'',
	  img1:'',img2:'',img3:'',img4:'',img5:''
  };
  
  },
  mounted() {
  	this.$http.get('http://156.251.24.193:81/index.php/api/index/getdwinfo')
  	.then(response => {
  		
  	    if(response.data.code == 1){
  			
  			var list = response.data.msg[0];
  			this.appname = list.appname;
  			this.apptext = list.appinfo;
  			this.appinfo = list.appmsg;
  			this.img1 = list.img1;
  			this.img2 = list.img2;
  			this.img3 = list.img3;
  			this.img4 = list.img4;
  			this.img5 = list.img5;
  			this.icon = list.icon;
  			this.applevel = list.level;
  			this.dwnumber = list.dwnumber;
  			this.dwurl = list.dwurl;
  			
  		}
  	    // 处理成功回调逻辑
  	})
  	.catch(error => {
  	    console.error(error);
  	    // 处理错误回调逻辑
  	});
  },
	methods: {
		az(){
			var url = this.dwurl;
			Dialog.confirm({
			  title: 'Google Play - '+this.appname,
						confirmButtonText:'Install',
						cancelButtonText:'Close',
			  message: 'The application is about to be installed on this device',
			})
			  .then(() => {
			    window.open(url);
			  })
			  .catch(() => {
			    // on cancel
			  });
			
		},
	  yw() {
	    Toast('Failed to add to wish list. Please try again later。');
	  },
	  fx(){
		  Toast('Network acquisition failed, please ensure that the network is functioning properly。');
	  },
	  login(){
		  Dialog.confirm({
		    title: 'Google Play',
			confirmButtonText:'Login',
			cancelButtonText:'Close',
		    message: 'Before proceeding with the operation, it is necessary to log in to your Google account for better verification。',
		  })
		    .then(() => {
		      Toast("Information retrieval failed, please try again later。")
		    })
		    .catch(() => {
		      // on cancel
		    });
	  }
	}
	
}
</script>

<style>
	*{ margin:0px; padding:0px;word-wrap: break-word;}
#app {
	
}
.juzhong {
	border: 0.1px solid #E0E2E5; /* 设置1像素的黑色实线边框 */
	
	border-radius: 15px;
	
	padding: 10px;
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中，如需 */
}
::-webkit-scrollbar {
    display: none;
}
.scroll-container {
  width: 100%;
  
  padding-bottom: 20px;
  margin-bottom: -10px;
  overflow-x: auto; /* 使得容器可以横向滚动 */
  white-space: nowrap; /* 防止内部内容换行 */
}
 
.content {
  width: fit-content; /* 根据内容自动调整宽度 */
}
</style>
